const base = '/utility'

export const utility = {
    get_biller_by_category: `${base}/billers`,
    get_biller_payment_items: `${base}/merchantServices`,
    send_payment_advice: `${base}/send-bill-payment-advice`,
    schedule: `${base}/schedule`,
    purchase: `${base}`,
    pay_with_card: `/guest-user${base}/card/pay`,
    pay_with_ussd: `/guest-user${base}/ussd/pay`,
    get_discount: `${base}/discount/value`,
    validate_customer_details: `${base}/customer-validation`,
    validate_network_details: `${base}/phone-number-validation`,
    get_recent_transactions: `${base}/recent/transactions`
}

export const mobile = {
    get_biller_by_category: `${base}/billers`,
    get_data_bundle: `${base}/data/operators`
}