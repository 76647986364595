export const INTERCOM_ID = 'mqrkre0d';
export const MINIMUM_GUEST_AMOUNT = 10000;
export const MINIMUM_VOUCHER_AMOUNT = 10;
export const PHONE_NUMBER_LENGTH = 11;
export const PASSWORD_LENGTH = 8;
export const BVN_LENGTH = 11;
export const NIN_LENGTH = 11;
export const REGEX_ACCOUNT_NUMBER = /^\d{10}$/;
export const PIN_LENGTH = 4;
export const REGEX_PHONE = /^\d{11}$/;
export const REGEX_EMAIL = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/i;
export const REGEX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>[\]\\/`~_\-+=;'`])[A-Za-z\d!@#$%^&*(),.?":{}|<>[\]\\/`~_\-+=;'`]{8,}$/;
export const REGEX_LETTER = /^[a-zA-Z]+$/;
export const REGEX_NAME = /^[a-zA-Z]{3,}-?[a-zA-Z]*$/;
export const REGEX_CITY = /^[a-zA-Z ]+$/;
export const REGEX_FULLNAME = /^[a-zA-Z ]+$/;
export const ADDRESS_REGEX = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9 ,-]+$/
export const DOJAH_DRIVERS_LICENSE_WIDGET_ID = '6504156005a8aa0041787e21'
export const DOJAH_VOTERS_CARD_WIDGET_ID = '6504160d05a8aa0041788d37'
export const DOJAH_INTERNATIONAL_PASSPORT_WIDGET_ID = '6504232033cd050043e590c8'
export const DOJAH_VNIN_WIDGET_ID = '6504224805a8aa004179a3bc'
export const DOJAH_NIN_WIDGET_ID = '6508466205a8aa004199a494'
export const INVALID_MESSAGE_LIST = ['Session expired', 'jwt malformed', 'Token session expired', 'Invalid/Expired Token', 'invalid signature']
export const POSTAL_CODE_LENGTH = 6
export const MAXIMUM_DOLLAR_AMOUNT = 5000
export const MAXIMUM_NAIRA_AMOUNT = 10000000
export const MAXIMUM_UTILITY_BILL_AMOUNT = 10000000
export const MINIMUM_ELECTRICITY_AMOUNT = 1000